import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node"
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useMatches,
  useRouteError,
} from "@remix-run/react"
import { captureRemixErrorBoundaryError } from "@sentry/remix"
import * as Sentry from "@sentry/remix"
import { useEffect, useMemo } from "react"
import { typedjson, useTypedLoaderData } from "remix-typedjson"

import stylesheet from "~/tailwind.css?url"

import { getPossiblyAuthenticatedUser } from "./modules/auth/auth.server"

export const links: LinksFunction = () => [{ rel: "stylesheet", href: stylesheet }]

export const ErrorBoundary =
  process.env.NODE_ENV === "development"
    ? undefined
    : () => {
        const error = useRouteError()

        useEffect(() => {
          captureRemixErrorBoundaryError(error)
        }, [error])

        if (isRouteErrorResponse(error) && error.status === 404) {
          return null
        } else {
          return (
            <html lang="en" className="h-full">
              <head>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width,initial-scale=1" />

                <Meta />
                <Links />
              </head>

              <body className="flex min-h-screen flex-col items-center justify-center gap-y-6">
                <h1 className="text-xl">Error</h1>

                <p className="text-md">Our apologies, something went wrong.</p>

                <p className="text-md">The developers have been notified.</p>
              </body>
            </html>
          )
        }
      }

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await getPossiblyAuthenticatedUser(request)

  return typedjson({ user })
}

export default function App() {
  const { user } = useTypedLoaderData<typeof loader>()

  const matches = useMatches()

  const isErrorPage = useMemo(
    () => matches[matches.length - 1].pathname === "/error_frontend",
    [matches]
  )

  useEffect(() => {
    if (import.meta.env.PROD && user) {
      Sentry.setUser({
        id: user.id,
        email: user.emails[0].email,
        username: user.person?.fullName,
      })
    }
  }, [user])

  return (
    <html lang="en" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />

        <Meta />
        <Links />
      </head>

      <body className="flex min-h-screen flex-col">
        <Outlet />

        <ScrollRestoration />
        <Scripts />

        {isErrorPage ? (
          <span className="w-full p-2 text-center text-xs">User ID: {user?.id}</span>
        ) : null}
      </body>
    </html>
  )
}
